.blog-card-container {
  border-radius: 14px;
  width: 400px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
}

.blog-card-meta {
  width: 100%;
  height: 200px;
}

.blog-card-content {
  padding: 32px;
  background-color: #fff;
  text-align: left;
  height: 320px;
}

.blog-card-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: #020313;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  height: 70px;
  white-space: wrap;
  overflow: hidden;
}

.blog-card-desc {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #020313;
  margin-bottom: 56px;
  height: 80px;
  white-space: wrap;
  overflow: hidden;
}

.blog-card-divider {
  border: 1px solid #EDEDF2;
  margin: 0px -32px 0px -32px;
  margin-bottom: 24px;
}

.blog-card-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}