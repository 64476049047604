.in-touch-container {
  width: 100%;
  border-radius: 16px;
  /* padding: 0px 32px 480px 100px; */
  /* text-align: left; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  /* border: 1px solid #23262f; */
  /* background-image: url("./bg.svg");
  background-repeat: no-repeat;
  background-size: cover; */
}

.in-touch-title {
  font-weight: 700;
  font-size: 72px;
  color: #020313;
  line-height: 86.4px;
  font-family: Circular, Helvetica, sans-serif;
}

.in-touch-title-chrome {
  font-weight: 700;
  font-size: 72px;
  color: #020313;
  line-height: 86.4px;
  font-family: Circular, Helvetica, sans-serif;
  word-spacing: -25px;
}

.error-input {
  color: rgb(230, 0, 122);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  /* margin-top: 1rem; */
}
.in-touch-content {
  color: #020313;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.in-touch-content-2 {
  margin-bottom: 100px;
}

.in-touch-email {
  max-width: 400px;
  /* margin-bottom: 20px; */
  border-radius: 8px;
  border: 1px solid rgba(119, 126, 144, .2);
  color: #040404;
  background-color: rgb(237, 237, 242);
}

.in-touch-form {
  display: flex;
  justify-content: center;
  /* margin-bottom: 1rem; */
  width: 740px;
}

.telegram-contact-item {
  margin-left: 25px;
}

.decor-item {
  z-index: 2;
}

.in-touch-contact {
  z-index: 10;
  margin-top: 114px;
}

.intouch-mobile-decor {
  position: relative;
  height: 564px;
}

.intouch-mobile-decor-left {
  position: absolute;
  left: 0;
  top: -40px;
}

.intouch-mobile-decor-middle {
  position: absolute;
  bottom: 80px;
  left: 112px;
}

.intouch-mobile-decor-right {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1169px) {
  .in-touch-container {
    flex-direction: column;
    /* width: 80%; */
    margin: 0 auto;
    /* padding: 0px 40px 20px 40px; */
    padding: 20px;
    /* background-position: top; */
  }
  .in-touch-form {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .in-touch-contact {
    display: flex;
    flex-direction: column;
  }

  .decor-item {
   display: none;
  }
}


@media screen and (max-width: 496px) {
  .in-touch-title {
    font-size: 32px;
  }
  .in-touch-title-chrome {
    font-size: 32px;
  }
  .in-touch-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 64px !important;
  }
  .in-touch-form-btn {
    margin-top: 24px;
  }
  .in-touch-form {
    margin-bottom: 16px;
  }
  .telegram-contact-item {
    margin-left: 0;
  }

  .in-touch-container {
    padding: 0px 20px !important;
  }

  .intouch-mobile-decorline {
    position: absolute;
    left: -45px;
    top: -70px;
  }

  .in-touch-content-2 {
    margin-bottom: 30px;
  }
}
