.blog-container {
  max-width: 1440px;
  padding: 0px 104px;
  margin: 80px auto 120px auto;
}
.blog-heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}
.blog-title {
  font-size: 72px;
  line-height: 86.4px;
  font-family: Circular, Helvetica, sans-serif;
  font-weight: 700;
  color: #020313;
  margin-bottom: 16px;
}

.blog-subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #020313;
}

.blog-author {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #e6007a;
}

.blog-content-container {
  padding: 0px 200px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: left;
  color: #020313
}

.blog-content-container li {
  margin-bottom: 20px;
}

.blog-content-container ol li {
  font-weight: 700;
}

.blog-content-container .blog-link-list {
  font-weight: 500 !important;
  padding-top: 10px;
  margin-top: 10px;
}

.blog-content-heading {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: #020313;
  margin-bottom: 32px !important;
}

.blog-content-heading-link {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: #1890ff;
  margin-bottom: 32px !important;
}

@media screen and (max-width: 960px) {
  .blog-content-container {
    padding: 0 !important;
  }
}

@media screen and (max-width: 496px) {
  .blog-container {
    padding: 0 32px;
  }
  .blog-content-container {
    padding: 0px;
   
  }
  .blog-title {
    font-size: 32px;
    line-height: 38.4px;
  }
}