.homeblog-container {
  background-color: #E6007A;
  padding: 120px 80px;
  overflow: hidden;
}

.homeblog-title {
  font-weight: 700;
  font-size: 72px;
  line-height: 86.4px;
  font-family: Circular, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 80px;
}

.homeblog-title-chrome {
  font-weight: 700;
  font-size: 72px;
  line-height: 86.4px;
  font-family: Circular, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 80px;
  word-spacing: -25px;
}

.homeblog-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px 24px;
  justify-content: center;
  margin-bottom: 80px;
}

.homeblog-readmore {
  background-color: #020313;
  color: #fff !important;
  padding: 16px 40px;
  border-radius: 14px;
  width: 193px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 496px) {
  .homeblog-title {
    font-size: 32px;
    line-height: 120%;
  }
  .homeblog-container {
    padding: 120px 32px
  }
}