.blogs-title {
  margin: 80px auto 120px auto;
}

.blog-list {
  padding: 0 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px 24px;
  max-width: 1440px;
  margin: 0 auto 80px auto;
}

.blogs-title-1 {
  color: #e6007a;
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;
}

.blogs-title-2 {
  color: #020313;
  font-size: 72px;
  font-weight: 500;
  line-height: 72px;
}

.blogs-navigators {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  justify-content: center;
  margin-bottom: 120px;
}

.arrow {
  border: solid #777E90;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.next-arrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.arrow-container {
  padding: 16px 40px;
  border-radius: 14px;
  width: 94px;
  cursor: pointer;
}

.prev-arrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.active-arrow {
  border-color: #fff !important;
}

@media screen and (max-width: 496px) {
  .blogs-title {
    margin: 64px auto;
  }
  .blogs-title-1 {
    font-size: 48px;
    line-height:120%;
  }
  .blogs-title-2 {
    font-size: 32px;
    line-height: 38.4px;
  }
  .blog-list {
    padding: 0px 32px;
    gap: 40px;
  }

  .blogs-navigators {
    padding: 0px 20px;  
  }
}